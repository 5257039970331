import './style.scss';

var initBlock = function(block) {
    // Si aucun bloc n'est fourni, on récupère les blocs du front-end
    if (!block) {
        return;
    }

    const bodyElement = block.ownerDocument.body;
    const triggerMenu = block.querySelectorAll('.trigger-menu, .background-overlay');
    const subPageButton = block.querySelectorAll('span.has-subpage');
    const backButton = block.querySelectorAll('.back-megadrop');

    triggerMenu.forEach(button => {
        button.addEventListener('click', function(e) {
            e.preventDefault();
            const isOpen = bodyElement.classList.toggle('menu-open');

            if (!isOpen) {
                // Si le menu est fermé, enlever toutes les classes 'active'
                block.querySelectorAll('.megadrop.active').forEach(megadrop => {
                    megadrop.classList.remove('active');
                });
                bodyElement.classList.remove('contrast');
            }
        });
    });

    subPageButton.forEach(link => {
        link.addEventListener('click', function(e) {
            e.preventDefault();
            const megadrop = this.nextElementSibling;
            if (megadrop && megadrop.classList.contains('megadrop')) {
                megadrop.classList.add('active');
            }
            checkNiv3Active(); // Vérifie l'état de niv3 après l'ajout
        });
    });

    backButton.forEach(button => {
        button.addEventListener('click', function(e) {
            e.preventDefault();
            const currentMegadrop = this.closest('.megadrop'); // Le megadrop actif

            if (currentMegadrop) {
                // Enlever la classe 'active' du megadrop actuel
                currentMegadrop.classList.remove('active');

                // Trouver le megadrop précédent (frère précédent)
                const previousMegadrop = currentMegadrop.previousElementSibling; // Le megadrop frère précédent

                if (previousMegadrop && previousMegadrop.classList.contains('megadrop')) {
                    // Si le frère précédent est un megadrop, l'activer
                    previousMegadrop.classList.add('active');
                }
            }
            checkNiv3Active(); // Vérifie l'état de niv3 après l'ajout
        });
    });

    function checkNiv3Active() {
        const niv3Megadrops = block.querySelectorAll('.megadrop.niv-3.active');
        console.log(niv3Megadrops);
        if (niv3Megadrops.length > 0) {
            bodyElement.classList.add('contrast'); // Ajoute la classe au body
        } else {
            bodyElement.classList.remove('contrast'); // Retire la classe si aucun niv3 n'est actif
        }
    }
};




// Fonction de déclenchement au chargement du DOM
document.addEventListener('DOMContentLoaded', () => {

    // Initialisation pour le front-end
    var SlideInblocks = document.querySelectorAll('.wp-block-acf-menu-slidein');
    SlideInblocks.forEach(block => {
        initBlock(block);
    });

    // Initialisation pour l'aperçu dans l'éditeur Gutenberg avec ACF
    if (window.acf) {
        window.acf.addAction('render_block_preview/type=menu-slidein', function($block) {
            // $block est passé par ACF comme un jQuery object, donc il faut utiliser [0] pour obtenir l'élément DOM natif
            initBlock($block[0]);
        });
    }

    // Ajout de la classe CSS au body lors du scroll
    window.addEventListener('scroll', function() {
        const scrollThreshold = 30; // Définir le seuil de défilement en pixels
        if (window.scrollY > scrollThreshold) {
            document.body.classList.add('scrolled');
        } else {
            document.body.classList.remove('scrolled');
        }
    });
});



